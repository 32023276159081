
























import { computed, defineComponent } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import accountStateRef from "@/apps/accounts/modules/store";

export default defineComponent({
  name: "Navbar",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
  },
  setup() {
    const pengelola = accountStateRef.me.pengelola;
    const imageUrl = computed(() => {
      const foto = pengelola?.foto;
      // const foto = accountStateRef.me.pengelola?.foto;
      return foto ? foto : "";
    });
    return {
      // Data
      brandText: APP_CONFIG.brandText,
      imageUrl,
      pengelola,
    };
  },
});
